import React, { CSSProperties, useEffect, useState } from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface NewReportProps {
  /**数据 */
  data: any
  /**展示更多按钮 */
  moreIcon?: any
  /**收起按钮 */
  packUpIcon?: any
}

interface ExtendedCSSProperties extends CSSProperties {
  '--itemBg'?: string
  '--defaultToLink'?: string
  '--hoverToLink'?: string
  '--showMore'?: string
  '--packUp'?: string
}

const NewReport: React.FC<NewReportProps> = (props) => {
  const { data, moreIcon = '', packUpIcon = '' } = props

  const [showMore, setShowMore] = useState<boolean>(false)
  //用于判断是否点击过展开或者收起
  const [clicked, setClicked] = useState<boolean>(false)
  const isMb = useIsWindowWidthSmaller()

  useEffect(() => {
    if (showMore === false && clicked) {
      const top = isMb ? 370 : 790
      window.scrollTo(0, top)
    }
  }, [showMore, isMb])

  const handleClick = (link) => {
    const a = document.createElement('a')
    a.href = link
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const style: ExtendedCSSProperties = {
    '--showMore': `url(${moreIcon.publicURL ?? moreIcon})`,
    '--packUp': `url(${packUpIcon.publicURL ?? packUpIcon})`,
  }

  return (
    <div className={styles.newReportWrapper} style={style}>
      {data.map((item, index) => {
        const ItemStyle: ExtendedCSSProperties = {
          '--itemBg': `url(${isMb ? item.itemBgMb.publicURL ?? item.itemBgMb : item.itemBg.publicURL ?? item.itemBg})`,
          '--defaultToLink': `url(${item.afterIcon.publicURL ?? item.afterIcon})`,
          '--hoverToLink': `url(${item.afterIconHover.publicURL ?? item.afterIconHover})`,
        }
        if (index > 4 && !showMore) {
          return null
        } else {
          return (
            <div
              className={classnames(styles.newReportItem, { [styles.newReportItemHover]: !isMb })}
              style={ItemStyle}
              key={item.title + index}
              onClick={() => {
                handleClick(item.link)
              }}
            >
              <img src={item.icon.publicURL ?? item.icon} className={styles.icon}></img>
              <div className={styles.textWrapper}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.desc}>{item.desc}</div>
              </div>
              <div className={styles.linkText}>
                <div className={styles.text}>{item.linkText}</div>
                <div className={styles.toLink}></div>
              </div>
            </div>
          )
        }
      })}
      {data.length > 4 && !showMore && (
        <div
          className={styles.showMore}
          onClick={() => {
            setShowMore(!showMore)
            setClicked(true)
          }}
        ></div>
      )}
      {data.length > 4 && showMore && (
        <div
          className={styles.packUp}
          onClick={() => {
            setShowMore(!showMore)
            setClicked(true)
          }}
        ></div>
      )}
    </div>
  )
}

export default NewReport
