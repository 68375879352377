import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/layout'
import { Seo } from 'components/index'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'
//组件
import Nav from 'components/Topic/Ds2023/Nav'
import Banner from 'components/Topic2023Review/Banner'
import Section from 'components/Topic2023/Section'
import NewReport from 'components/Topic2023Review/NewReport'
import Content from 'components/Topic2023Review/Content'
import Thumbs from 'components/Topic2023Review/Thumbs'
import Ds2023ReviewSwiper from 'components/Topic2023/Ds2023ReviewSwiper'
import Ds2023Footer from 'components/Topic2023/Ds2023Footer'

import QrCode from 'assets/images/topic/ds2023Review/qrcode.png'

const Ds2023Review = (props) => {
  const { data } = props
  const { ds2023ReviewYaml } = data
  const { nav, banner, newReport, content, moment, review } = ds2023ReviewYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="2023神策数据驱动大会_精彩回顾"
        description="第八届神策数据驱动大会以“新旅程、新经营，决胜数字化”为主题，与各个行业的数据专家、企业领袖围绕数字化客户经营展开深入探讨与交流，会上多位嘉宾基于不同行业视角和业务场景分享了企业数字化转型实践，为广大参会者带来一场思维盛宴！"
        keywords="神策数据驱动大会2023,第8届数据驱动大会,大会回顾,数据驱动,数字化,客户经营"
      />
      <div className={styles.ds2023ReviewRoot}>
        {/* 导航 */}
        <Nav {...nav} isReview signUpClass={styles.signUpClass} />
        {/* banner */}
        <Banner {...banner} />
        {/* 新闻报道 */}
        <Section title={newReport?.title} mask_bg={newReport?.mask_bg?.publicURL} mask_className={styles.newReportMask}>
          <NewReport data={newReport.data} moreIcon={newReport.moreIcon} packUpIcon={newReport.packUpIcon} />
        </Section>
        {/* 内容精华  */}
        <Section
          mask_className="lg:!h-[52px] !top-0 !h-[36px]"
          title={content?.title}
          mask_bg={content?.mask_bg?.publicURL}
        >
          <Content content={content} />
        </Section>
        {/* 现场盛况 */}
        <Section title={moment?.title} mask_bg={moment?.mask_bg?.publicURL} mask_className={styles.momentMask}>
          <Thumbs imgArr={moment?.imgArr} />
        </Section>
        {/* 往届回顾 */}
        <Section
          mask_className="!h-[26px] !top-[10px] lg:!h-[50px]"
          title={review?.title}
          mask_bg={review?.mask_bg?.publicURL}
        >
          <div className="lg:w-[1200px] mx-auto lg:px-0 px-[20px]">
            <Ds2023ReviewSwiper dataSource={review?.review_dataSource} />
          </div>
        </Section>
        {/* qrCode */}
        <div className="text-center lg:my-[170px] my-[80px]">
          <div>
            <img
              className="w-[92px] h-[92px] lg:w-[160px] lg:h-[160px]"
              src={QrCode}
              alt="关注「神策数据」公众号了解大会资讯"
            />
          </div>
          <div className="text-[14px] mt-[10px] lg:mt-[18px] leading-[18px] text-[#fff]">
            关注「神策数据」公众号了解大会资讯
          </div>
        </div>
        <Ds2023Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    ds2023ReviewYaml {
      nav {
        signUpLink
        appointmentLink
      }
      banner {
        title
        subTitle
        link
      }
      newReport {
        title
        mask_bg {
          publicURL
        }
        moreIcon {
          publicURL
        }
        packUpIcon {
          publicURL
        }
        data {
          icon {
            publicURL
          }
          title
          desc
          linkText
          link
          afterIcon {
            publicURL
          }
          afterIconHover {
            publicURL
          }
          itemBg {
            publicURL
          }
          itemBgMb {
            publicURL
          }
        }
      }
      moment {
        title
        mask_bg {
          publicURL
        }
        imgArr {
          itemImg {
            publicURL
          }
        }
      }
      content {
        title
        mask_bg {
          publicURL
        }
        data {
          name
          list {
            item {
              value
              mb_bg
              dataSource {
                title
                position
                theme
                person_name
                theme_photo {
                  publicURL
                }
                theme_content
              }
            }
          }
        }
      }
      review {
        title
        mask_bg {
          publicURL
        }
        review_dataSource {
          number
          link
          img_url {
            childImageSharp {
              gatsbyImageData(formats: JPG)
            }
          }
          year
          desc
        }
      }
    }
  }
`

export default Ds2023Review
