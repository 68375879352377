// extracted by mini-css-extract-plugin
export var desc = "index-module--desc--bQGFI";
export var icon = "index-module--icon--qS9Je";
export var linkText = "index-module--linkText--Rm4Mk";
export var newReportItem = "index-module--newReportItem--UFTSD";
export var newReportItemHover = "index-module--newReportItemHover--LGs0y";
export var newReportWrapper = "index-module--newReportWrapper--ggSsO";
export var packUp = "index-module--packUp--pOkFo";
export var showMore = "index-module--showMore--PJ1WH";
export var text = "index-module--text--JI5Xo";
export var textWrapper = "index-module--textWrapper--QRuE-";
export var title = "index-module--title--rYQOB";
export var toLink = "index-module--toLink--7hhAk";